import React from 'react'
import styled from 'styled-components'
require('prismjs/themes/prism.css')

const Body = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};

  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.5em;
    margin-top: 80px;
  }
  h2 {
    font-size: 2em;
    margin: 5rem 0 2rem 0;
    position: relative;
    padding: 5px 0px 5px 17px;
  }
  h2::before {
    position: absolute;
    top: 0px;
    left: 0px;
    content: "";
    display: inline-block;
    width: 5px;
    height: 100%;
    border-radius: 5px;
    background: #5b8bf7;/*linear-gradient(0deg,#b3d4fc 0%,#b3d4fc 50%,#5b8bf7 50%,#5b8bf7 100%);*/
  }
  h3 {
    font-size: 1.5em;
  }
  p {
    font-size: 1.2em;
    line-height: 1.9;
    margin: 0 0 2em 0;
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 1em;
      line-height: 1.8;
    }
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 2;
      font-size: 1em;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }

  strong {
    background: linear-gradient(transparent 70%, rgba(107, 182, 255, 0.15) 50%);
  }

  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    span {
        margin-bottom: 60px;
        img {
          max-width: 100vw !important;
          width: 100vw !important;
          height: auto !important;
          margin: 0px -1.5em !important;
        }
    }
  }
`

const PageBody = props => {
  return (
    <Body
      dangerouslySetInnerHTML={{ __html: props.body.childMarkdownRemark.html }}
    />
  )
}

export default PageBody
