import React from "react";
import styled from 'styled-components'
import AdSense from 'react-adsense'

const Wrapper = styled.div`
  margin: 3em 0;
`

const Ad = () => (
  <Wrapper>
    <AdSense.Google
        client="ca-pub-5800512485406653"
        slot="1406180666"
        format="rectangle"
    />
  </Wrapper>
  )
export default Ad
